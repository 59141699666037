@import '../../styles/variables';
@import '../../styles/mixins';

.cart_coupon {

  font-size: .81rem;

  .invalid {
    color: red;
  }

  .coupon_input {
    width: 100%;
  }

  .have_coupon {
    display: flex;
    align-items: center;
    color: #414141;
    margin: 0;
  }

  .apply {
    background-color: #ff5733;
    text-transform: uppercase;
    line-height: 1rem;
    height: 100%;
    width: 100%;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

}