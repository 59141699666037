@import '../styles/variables';
@import '../styles/mixins';

.navs_container {
  position: fixed;
  top: $header-height;
  right: 0;
  left: 0;
  z-index: 1030;
  //width: 1440px;
  margin: auto;
}

.container {
  position: relative;
}

.sub_header {
  background: $header-background-color;
  height: $sub-header-height;
  color: $header-color;
  box-shadow: 0 2px 4px 0 $header-shadow-color;
  background-color: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;

  .worldCup {
    color: $naranja !important;
  }

  .ball {
    zoom: 0.4 !important;
  }

  .items {
    font-family: Roboto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: 14px;
    color: #7c7e95;
    .icon {
      margin-right: 10px;
      color: $naranja;
    }
    .arrow {
      width: 9.9px;
      color: #65697c;
      margin-left: 0.6rem;
    }
    .promotion {
      margin-left: 4rem;
    }
    a {
      font-size: 14px;
      color: #7c7e95;
      text-decoration: none;
    }
    .categories {
      .sub_menu {
        width: 30rem;
        .header_sub_menu {
          margin-top: 1.5rem;
          margin-right: 1.5rem;
          border-bottom: solid 1px $category-dropdown-background-color-selected;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          a {
            color: $naranja;
            font-size: .7rem;
          }
        }
      }
      .subcategories {
        cursor: pointer;
        z-index: 1;
        background-color: #ffffff;

        .subcategory {
          height: 3rem;
          font-size: .8rem;
          border-bottom: solid 1px $category-dropdown-background-color-selected;
          &:hover {
            .bar {
              visibility: visible;
            }
          }
          .bar {
            background-color: $category-dropdown-background-color-selected;
            border-left: solid 4px #ff5733;
            float: left;
            width: 4px;
            height: 3rem;
            visibility: hidden;
            &.selected {
              visibility: visible;
            }
          }
          &.selected {
            background-color: $category-dropdown-background-color-selected;
          }
          a {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 14px;
            color: #7c7e95;
            text-decoration: none;
            &:hover, &:visited, &:focus {
              outline: none;
              text-decoration: none;
            }
          }

        }
      }

    }
  }
}
