@import '../../styles/variables';
@import '../../styles/mixins';

.newsletter_suscribe {
  //background-color: #eee;
  border: 1px solid $field-border-color;
  display: flex;
  align-items: center;
  padding-left: 22px;

  height: 52px;
  background-color: #ffffff;

  input {
    height: 24.7px;
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: normal;
    color: #727377;
    border: 0;
    background-color: #ffffff;
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #727377;
    }
    &::ff5733 { /* Firefox 19+ */
      color: #727377;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #727377;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #727377;
    }
  }

  button {
    width: 52px;
    height: 52px;
    color: white;
    border: none;
    background-color: $naranja;
    border-radius: 0;
    .button_icon {
      width: 22.1px;
      height: 15px;
      font-size: 15px;
      font-weight: 300;
      color: #ffffff;
      font-size: 15px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
  @include media-breakpoint-up(md) {
    width: 440.1px;
  }
}
