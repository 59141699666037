@import '../../../styles/variables';
@import '../../../styles/mixins';

.catalog_product_card{
  width: 100%;
  height: 160px;

  @include media-breakpoint-up(md) {
    width: $product-card-width;
    height: $product-card-height;
  }
}

.view_more {
  border: 4px solid $product-card-border-color;
  border-top: none;
  height: $product-card-block-height;

  .view_more_button {
    font-size: 0.8em;
    width: 100%;
  }
}
