@import '../../styles/variables';

.order_return_form {
  font-size: 0.8em;

  .help {
    position: absolute;
    margin-left: 8.5rem;
  }

  .help_icon {
    color: blue;
  }

  h3 {
    color: $main-color;
    font-size: 1.6em;
  }

  .button_text {
    text-transform: uppercase;
    width: 12.69rem;
    height: 3.06rem;
    background-color: $primary;
    font-size: .84rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;

    span {
      height: 18px;
    }
  }

  .checkbox_container {
    display: flex;
    justify-content: flex-start;
    align-items: normal;
    margin-bottom: 2px;
  }

  .checkbox {
    height: 15px;
    width: 15px;
    margin-right: 10px;
    border: 1px solid $field-border-color;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    padding-left: 1.5rem;
    box-shadow: 0 0 0 0;

    &:focus {
      box-shadow: 0 0 0 0;
      border: solid 2px $field-border-color-focus;
    }

    .is-invalid {
      &:focus {
        box-shadow: 0 0 0 0;
      }
    }
  }

  .field {
    height: 40px;
    border: 1px solid $field-border-color;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    box-shadow: 0 0 0 0;

    &:focus {
      box-shadow: 0 0 0 0;
      border: solid 2px $field-border-color-focus;
    }

    .is-invalid {
      &:focus {
        box-shadow: 0 0 0 0;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield; /* Firefox */
    }

  }

  .submit_button {
    background-color: $primary;
  }
}
