@import '../styles/variables';

.icon {
  width: 18px;
  height: 15px;
  color: #ff5733;
}
.sidebar {
  top: 0px;
}

.worldCup {
  color: $naranja !important;
}

.ball {
  zoom: 0.4 !important;
}

.header_sidebar {
  span {
    font-size: 20px;
    color: #414141;
    opacity: 1;
  }
  // opacity: 0.19;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to right, rgba(228, 228, 228, 0) 1%, rgba(228, 228, 228, 0.02) 14%, rgba(228, 228, 228, 0.07) 28%, rgba(228, 228, 228, 0.15) 41%, rgba(228, 228, 228, 0.28) 54%, rgba(228, 228, 228, 0.43) 67%, rgba(228, 228, 228, 0.62) 80%, rgba(228, 228, 228, 0.85) 92%, #e4e4e4);
}
.menu_item {
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: normal;
  color: #7c7e95;
  padding-left: .87rem;
  padding-top: .8rem;
  padding-bottom: .8rem;

  &.category_color_1 {
    color: $brand-color-1;
  }
  &.category_color_2 {
    color: $brand-color-2;
  }
  &.category_color_3 {
    color: $brand-color-3;
  }
  &.category_color_4 {
    color: $brand-color-4;
  }
  &.category_color_5 {
    color: $brand-color-5;
  }
  &.category_color_6 {
    color: $brand-color-6;
  }
  &.category_color_7 {
    color: $brand-color-7;
  }
  &.category_color_8 {
    color: $brand-color-8;
  }
}
.subcategory {
  color: #353535;
}
:global {
  /* Individual item */
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    color: #7c7e95;
    padding-right: 1rem;

    &, &:visited, &:focus, &:hover, &:visited {
      text-decoration: none;
      outline: none;
    }
  }

  /* Change color on hover */
  .bm-item:hover {
    background-color: #f4f4f8;
  }

  /* The rest copied directly from react-burger-menu docs */

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
    z-index: 3000 !important;
    display: none;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 0px;
    width: 0px;
    display: none;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: black;
  }

  /* General sidebar styles */
  .bm-menu {
    background: white;
    padding: 0;
    font-size: 1.2em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    a:hover {
      color: #7c7e95;
      text-decoration: none;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    background: $overlay-color;
  }
}

.button_close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: white;
  border-width: 0px;
  background-color: rgba(0,0,0,0);
  svg {
    color: #b8bae6;
  }
}

.separator {
  width: 100%;
  border: solid .5px #e8e9f0;
}
