@import '../styles/variables';

  .button, a.button {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    padding: .25rem 1rem .25rem 1rem;
    cursor: pointer;
    height: 100%;
    color: $main-color;

    &:hover, &:visited, &:focus {
      outline: none;
      text-decoration: none;
    }

    &:hover {
      background: $main-color;
      color: white;
    }

    &.rounded {
      border-radius: 15px;
    }

    &.border {
      border-color: $main-color;
    }

    &.dark {
      background: $main-color;
      color: white;

      &:hover {
        background: white;
        color: $main-color;
      }
    }
    &.secondary {
      background: #b1b2b2;
      color: white;
      border-color: #b1b2b2;
      
      &:hover {
        background: white;
        color: $main-color;
      }
    }
    .text {
      color: $main-color;
      font-size: 0.9em;
      text-transform: uppercase;

      &.dark {
        color: white;
      }
    }
    &.disabled {
      background-color: grey !important;
      color: white;
      border-color: grey;
      cursor: arrow;
      &:hover {
        cursor: arrow;
        background-color: grey;
        color: white;
      }
    }
  }
