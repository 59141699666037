@import '../../../styles/mixins';

.product_row :global {

  border-bottom: 1px solid $product-card-border-color;

  a.product {
    text-decoration: none;

    .image {
      padding-left: 5rem;
      position: relative;

      img {
        width: 10rem;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 1rem;
        margin-left: 0;
        img {
          width: 7rem;
        }
      }
    }

    .name {
      font-size: 1.3em;
      color: $main-color;
      font-weight: 600;
      flex-grow: 2;
      text-align: left;
      width: 40%;
      max-width: 40%;

      @include media-breakpoint-down(lg) {
        font-size: 1rem;
      }

      &:hover {
        color: $main-color-hover;
      }
    }

    small {
      color: $product-card-color;
    }

    .price {
      text-align: left;
    }

    .price_and_discount {
      height: $product-card-block-height;
      font-size: 0.9em;
      @include media-breakpoint-up(lg) {
        font-size: 1.3em;
      }
    }
  }
}
