@import '../styles/variables';

.shipping_data {
  font-size: 0.8em;

  h3 {
    color: $main-color;
    font-size: 1.6em;
  }

  .billing_address_check {
    margin-bottom: 1em;
  }

  .checkbox_container {
    display: flex;
    justify-content: flex-start;
    align-items: normal;
    margin-bottom: 2px;
    font-size: 1.2em;
  }

  .checkbox {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border: 1px solid $field-border-color;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    padding-left: 1.5rem;
    box-shadow: 0 0 0 0;

    &:focus {
      box-shadow: 0 0 0 0;
      border: solid 2px $field-border-color-focus;
    }

    .is-invalid {
      &:focus {
        box-shadow: 0 0 0 0;
      }
    }
  }

  .button_text {
    // letter-spacing: 3px;
    text-transform: uppercase;
    width: 12.69rem;
    height: 3.06rem;
    background-color: #ff5733;
    font-size: .84rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    span {
      height: 18px;
    }
  }
  .field {
    height: 40px;
    border: 1px solid $field-border-color;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    box-shadow: 0 0 0 0;
    &:focus {
      box-shadow: 0 0 0 0;
      border: solid 2px $field-border-color-focus;
    }
    .is-invalid {
      &:focus {
        box-shadow: 0 0 0 0;
      }
    }
  }
}
