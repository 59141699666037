@import '../styles/variables';
@import '../styles/mixins';

.footer {
  color: $footer-color;
  font-weight: 100;
  line-height: 1.2;

  .info_box {
    //width: 1440px;
    .suscribe_box {
      //width: 47%;
      .margin_elems {
        display: flex;
        flex-direction: column;
        margin-left: 10.6rem;
      }
      .title {
        height: 20px;
        font-family: Roboto;
        font-size: 16px;
        line-height: 1.25;
        color: $text-color-primary;
        font-family: Roboto;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
      }
      .subtitle {
        height: 47px;
        font-family: Roboto;
        font-size: 14px;
        line-height: 1;
        color: #aaa8ac;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
      }
    }

    .footer_separator {
      width: 1px;
      height: 132px;
      border: solid 1px #e2e2e2;
    }
    .generic_info_box {
  //Entre circulo y circulo hay 69 pixels
      .oval_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        &:hover {
          cursor: pointer;
        }
      }
      .oval {
        width: 75px;
        height: 75px;
        border: solid 1px $naranja;
        border-radius: 50%;
        //margin-left: 1.35rem;
        margin-bottom: .7rem;
        .icon {
          position: relative;
          //width oval / 2 - icon width / 2
          left: 19px;
          //height oval / 2 - icon height / 2
          top: 23px;
          width: 37px;
          height: 29px;
          font-size: 29px;
          font-weight: 300;
          color: $naranja;
        }
      }
      .description {
        height: 28px;
        font-family: Roboto;
        font-size: 11px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: center;
        color: #65697c;
        text-transform: uppercase;
      }
    }
  }


  a, a:hover, a:visited {
    color: #aaa8ac;
    text-decoration: none;
  }

  .legal_footer {
    max-width: 1200px;
    p {
      font-size: small;
    }
  }

  .fast_links {
    //width: 1440px;
    margin: auto;
    justify-content: center;
    height: 46px;
    background-color: $footer-background-color-fast-links;
    align-items: center;
    .icon {
      width: 23px;
      height: 23px;
      color: #ffffff;
    }
  }
  .map {
    //width: 1440px;
    min-height: 174px;
    background-color: #ffffff;
    .categorias {
      margin-top: 28px;
      width: auto;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: $text-color-primary;
      .categoria {
        margin-top: 0.3rem;
        font-weight: normal;
        font-size: 13px;
        line-height: 1.3rem;
      }
    }
  }

  .copyright {
    //width: 1440px;
    color: #ffffff;
    background-color: #000000;
    height: 29px;
    font-family: Roboto;
    font-size: 11px;
    letter-spacing: 0.92px;
    align-items: center;
  }

  .icon {
    margin-right: 0.25rem;
  }

  .logo {
    height: 50px;
  }

  .links {
    font-size: 0.7em;

  }

  .cft {
    height: 210px;
    background: white;
    border-radius: 50px;
    color: $main-color;
    font-weight: 600;
    font-size: 6em;
    letter-spacing: -5px;
  }

  .sitio_seguro {
    img {
      height: 50px;
    }
  }

  .disclojure {

  }

  @include media-breakpoint-up(md) {
    .info_box {
      .suscribe_box {
        border-right: solid 2px #e2e2e2;
        .title {
          width: 376px;
        }
        .subtitle {
          width: 396px;
        }
      }
      .generic_info_box {
        .oval_container {
          width: auto;
        }
      }
    }

  }

  @include media-breakpoint-up(lg) {
    .logo {
      height: 70px;
    }
    font-size: 1.6em;
  }
}
