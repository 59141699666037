@import '../../styles/variables';
@import '../../styles/mixins';

.summary {
  //font-weight: normal;
  //font-stretch: normal;
  //font-style: normal;
  //line-height: normal;
  //letter-spacing: normal;
  .frame {
    padding: 1.5rem 0rem 1rem 0rem;
  }

  .brand {
    height: 15px;
    font-size: 10px;
    color: $text-color-primary;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .price {
    font-size: 1.88rem;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    .price_strike {
      height: 1.5rem;
      font-size: 1.25rem;
      font-weight: normal;
      color: #7c7e95;
    }
    .strike {
      color: #7c7e95;
      text-decoration: line-through;
    }
  }
  .separator {
    border: solid 1px #f6f6f6;
      margin-left: -2.5rem;
  }

  .description_container {
    overflow: hidden;
    font-size: 1.25rem;
    //width: 22,5rem;
    line-height: 1.09;
    color: #414141;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .colors {
    color: black;
    &.category_color_1 {
      &.font {
        color: $brand-color-1;
      }
      &.button {
        border: 1px solid $brand-color-1;
        border-color: $brand-color-1;
        border-radius: 4px;
        &:hover {
          background-color: rgba($brand-color-1, 0.4);
        }
      }
    }
    &.category_color_2 {
      &.font {
        color: $brand-color-2;
      }
      &.button {
        border: 1px solid $brand-color-2;
        border-color: $brand-color-2;
        &:hover {
          background-color: rgba($brand-color-2, 0.4);
        }
      }
    }
    &.category_color_3 {
      &.font {
        color: $brand-color-3;
      }
      &.button {
        border: 1px solid $brand-color-3;
        border-color: $brand-color-3;
        &:hover {
          background-color: rgba($brand-color-3, 0.4);
        }
      }
    }
    &.category_color_4 {
      &.font {
        color: $brand-color-4;
      }
      &.button {
        border: 1px solid $brand-color-4;
        border-color: $brand-color-4;
        &:hover {
          background-color: rgba($brand-color-4, 0.4);
        }
      }
    }
    &.category_color_5 {
      &.font {
        color: $brand-color-5;
      }
      &.button {
        border: 1px solid $brand-color-5;
        border-color: $brand-color-5;
        &:hover {
          background-color: rgba($brand-color-5, 0.4);
        }
      }
    }
    &.category_color_6 {
      &.font {
        color: $brand-color-6;
      }
      &.button {
        border: 1px solid $brand-color-6;
        border-color: $brand-color-6;
        &:hover {
          background-color: rgba($brand-color-6, 0.4);
        }
      }
    }
    &.category_color_7 {
      &.font {
        color: $brand-color-7;
      }
      &.button {
        border: 1px solid $brand-color-7;
        border-color: $brand-color-7;
        &:hover {
          background-color: rgba($brand-color-7, 0.4);
        }
      }
    }
    &.category_color_8 {
      &.font {
        color: $brand-color-8;
      }
      &.button {
        border: 1px solid $brand-color-8;
        border-color: $brand-color-8;
        &:hover {
          background-color: rgba($brand-color-8, 0.4);
        }
      }
    }
  }
  .discount {
    height: 18px;
    font-size: 15px;
    font-weight: bold;
    color: #1559bc;
    text-transform: uppercase;
  }

  .list_installments {
    font-size: 0.8em;
    font-weight: 600;
    padding-top: 0.5rem;
    border-top: 1rem solid #f6f6f6;
    padding-left: 1rem;
    padding-right: 1rem;
    .title {
      height: 1rem;
      font-size: .88rem;
      color: #ff5733;
      font-weight: normal;
    }
    .installments {
      color: $naranja;
    }
    .link_see_all_supports_pay{
      padding-top: 1.3rem;
      font-size: .88rem;
      font-weight: normal;
      text-align: center;
      margin-bottom: 1rem;

      a {
        color: #ff5733;
      }
    }
  }

  .envio {
    border-top: 1rem solid #f6f6f6;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    font-size: 14px;
    color: #ff5733;
    align-items: center;

    .envio_logo {
      width: 23px;
      height: 18px;
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      color: #ff5733;
      margin-right: .9rem;
    }

    .summary {
      line-height: 1.2em;
      font-size: 0.65rem;
    }
  }
  .card_list_container {
    padding-top: .8rem;
    margin-bottom: 1.2rem;

    .logo {
      height: 41px;
      border-radius: 8px;
      border: solid 1px #f6f6f6;
      margin-right: 1rem;
    }

    .card_list {
      img {
        height: 40px;
      }
    }
  }
  .actions {
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    left: 0;
    right: 0;
    background: white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .button {
    width: 273px;
    height: 2.5rem;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.29);
    background-image: linear-gradient(to bottom, #ff5733, #e24045 190%);
    border-width: 0px;
  }
  @include media-breakpoint-up(md) {
    .frame {
      padding: 2.5rem 0rem 0rem 2.5rem;
    }
    .brand {
      font-size: 13px;
      padding-left: 0;
      padding-right: 0;
    }

    .description_container {
      font-size: 1.45rem;
      height: 3.44rem;
      padding-left: 0;
      padding-right: 0;
    }

    .price {
      margin-bottom: 2.37rem;
      padding-left: 0;
      padding-right: 0;
    }
    .list_installments {
      border-top: none;
      padding-left: 0;
      padding-right: 0;
    }

    .envio {
      border-top: none;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      margin-top: 1.13rem;
      margin-bottom: 1.31rem;
    }

    .actions {
      justify-content: flex-end;
      position: relative;
      background: transparent;
      padding-top: 0;
      padding-bottom: 0;
    }
    .button {
      height: 3.9rem;
    }
  }
}
