@import '../styles/variables';
@import '../styles/mixins';

$font-color: #525252;

.breadcrumbs {
  font-size: 0.75rem;
  height: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $naranja;
  margin-top: 1.1rem;
  margin-bottom: 0.9rem;
  align-items: center;

  justify-content: center;
  @include media-breakpoint-up(lg) {
    width: auto;
    justify-content: flex-start;
  }
  :global {

    .breadcrumb {
      background: transparent;
      margin-bottom: 0;
      padding: 0px;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.2rem;
        @include media-breakpoint-up(lg) {
          padding-right: 0.5rem;
        }
        color: $naranja;
        content: ">";
    }

    .breadcrumb-item {
      font-weight: 500;
      font-size: 0.7rem;
      //padding-left: 0.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 0.8rem;
      }
      a {
        color: $naranja;
        &:hover {
          text-decoration: none;
        }
      }

      &.active {
        color: $naranja;
        font-weight: 900;
      }
    }
  }

}
