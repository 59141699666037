@import '../../styles/variables';
@import '../../styles/mixins';

.cart_item {
  font-size: 1.1em;
  align-items: baseline;
  background-color: #ffffff;
  @include media-breakpoint-up(md) {
    width: 45rem;
  }
  height: 6.25rem;
  a {
    text-decoration: none;
    cursor: pointer;
  }
  .header {
    font-size: .9em;
    font-style: italic;
    .before {
      color: rgba(grey, .6);
    }
  }

  .name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
  .picture {
    margin-top: 1.5rem;
    margin-bottom: 1.06rem;
    img {
      height: 59px;
      width: 65px;
    }
  }

  .quantity_controls {
    border: solid 1px #c8c8c9;
    border-radius: 25px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    .quantity_input {
      border: 0px;
      font-size: 12.3px;
      font-weight: bold;
      letter-spacing: 0.38px;
    }
    .quantity_control {
      display: flex;
      cursor: pointer;
      .icon {
        color: #ff5733;
        width: 6.5px;
        height: 1rem;
      }
    }
  }

  .price {
    margin-top: .2rem;
    height: 1.19rem;
    font-size: .9rem;
    font-weight: bold;
    color: #414141;
  }
  .actions {
    @include media-breakpoint-up(md) {
      margin-top: 1.06rem;
    }
    .trash {
      .icon {
        height: 15px;
        width: 13px;
        font-size: 1.5rem;
        color: #aaa8ac;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
  .sm_label {
    text-align: left;
  }
  .sm_value {
    text-align: center;
  }
  .separator_vertical {
    height: 100px;
    border-right: 1px solid $header-border-color;
  }
  .description {
    width: 279px;
    height: 26px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #aaa8ac;
    overflow: hidden;
  }
  .quantity_and_trash_align {
    justify-content: space-evenly;
    height: 100%;
    align-items: flex-end;
  }
}

.separator {
  margin-top: 0.5rem;
}

.cart_item_summary {
  font-size: .75rem;
  line-height: 1.25;
  letter-spacing: normal;
  .name {
    color: #414141;
  }
  .description {
    color: #aaa8ac;
    height: 1.88rem;
    overflow: hidden;
  }
  .quantity {
    color: #ff5733;
  }
  .price {
    font-size: .88rem;
    font-weight: bold;
    text-align: right;
    color: #414141;
  }
}
