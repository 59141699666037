@import '../styles/variables';

.term_and_conditions {
  font-size: 0.8em;
  h2 {
    color: $main-color;
    font-weight: 600;
    font-size: 2em;
  }
  h3 {
    font-size: 1em;
    font-weight: 600;
  }
}
