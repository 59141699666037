// Bootstrap override
$primary: #353535;
$secondary: #FF9104;

$naranja: #ff5733;

$text-color-primary: #414141;

$body-background-md-color: #f6f6f6;

$body-background-color: #ffffff;

$object-background-color: #ffffff;

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

// HEADER
$super-header-height: 0px;
$super-header-height-xs: 0px;
//$header-bottom-height: 150px;
$header-height: 70px;
$header-height-xs: 55px;

$header-shadow-color: #e8e9f0;

$sub-header-height: 39px;

$total-header-height: $header-height + $sub-header-height;
$total-header-height-xs: $super-header-height-xs + $header-height-xs;

// BOOTSTRAP OVERRIDES
//$container-max-widths: (
//  sm: 600px,
//  md: 800px,
//  lg: 1000px,
//  xl: 1440px  // <-- modified!
//);

//COLORS
$main-color: $primary;
$secondary-color: $secondary;
$main-color-dark: #337ab7;
$main-color-hover: #23527c;
$overlay-color: rgba(0, 0, 0, 0.3);
$category-dropdown-background-color: rgba(0, 55, 97, .7);

$brand-color-1: #f0c927;
$brand-color-2: #c5a7b4;
$brand-color-3: #ea7e74;
$brand-color-4: #81a5c0;
$brand-color-5: #a17a94;
$brand-color-6: #f4ad71;
$brand-color-7: #bc9e77;
$brand-color-8: #aac079;
$brand-color-default: #c5c5c5;
$header-burger-menu: #8094a1;
$header-burger-menu-background-color: #f4f4f8;

$header-background-color: white;
$header-color: #0a0a0a;
$header-border-color: #b1b2b2;

//Home
$featured-products-title-background: #f1f1f1;
$featured-products-title-color: black;


//PRODUCTCARD
$product-card-picture-height: 185px;
$product-card-block-height: 30px;
$product-card-color: #525252;
$product-card-border-color: #eee;

$product-card-picture-height-extended: 107.1px;
$product-card-picture-width-extended: 9rem;

$product-card-width: 213.7px;
$product-card-height: 290px;

//PRODUCT DETAIL
$product-color: $product-card-color;

//FOOTER
$footer-background-color-fast-links: $naranja;
$footer-background-color: #ffffff;
$footer-color: #7A7A7A;

//FIELDS
$field-border-color: #d0d7dd;
$field-border-color-focus: #ff5733;


$category-dropdown-background-color-selected: #f4f4f8;
$sub-category-menu-background-color: #F4F4F4;

$carousel-indicator-color: #dcdad9;
$carousel-indicator-color-selected: #b5b5b5;
