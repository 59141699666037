@import '../styles/variables';
@import '../styles/mixins';

.result_header {
  margin-top: 2rem;
  //margin-left: 10.6rem;
  //margin-right: 7.5rem;
}

.paymentResult {
  //width: 1440px;
  margin-left: -.45rem;
  font-size: 0.8rem;
  background-color: $naranja;
  color: #ffffff;
  height: 55.69rem;

  @include media-breakpoint-up(md) {
    font-size: 0.7em;
  }

  .icon {
    width: 2.38rem;
    height: 2.31rem;
  }

  .button_text {
    // letter-spacing: 3px;
    text-transform: uppercase;
    width: 21.69rem;
    height: 3.06rem;
    border: solid 1px #ffffff;
    background-color: $naranja !important;
    font-size: .81rem;
    font-weight: bold;
    color: #ffffff;
  }

  .text_center {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .accion {
    font-size: 1.88rem;
    font-weight: bold;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .result {
    font-size: 1.88rem;
    font-weight: bold;
  }
  .info {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .support {
    font-size: 1.3rem;
    font-weight: bold;
    a {
      color: #ffffff;
    }
  }
}
