@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
@import 'styles/variables';
@import 'styles/mixins';
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/react-redux-toastr/src/styles/index";

@font-face {
  font-family: UniNeue;
  src: url('./assets/fonts/uni-neue/UniNeueRegular.otf') format('opentype');
}

@font-face {
  font-family: UniNeueHeavy;
  src: url('./assets/fonts/uni-neue/UniNeueHeavy.otf') format('opentype');
}

@font-face {
  font-family: UniNeueLight;
  src: url('./assets/fonts/uni-neue/UniNeueLight.otf') format('opentype');
}

body {
  padding-top: $total-header-height-xs;

  font-family: 'Roboto', sans-serif !important;
  overflow: hidden;

  background-color: $body-background-md-color;
  @include media-breakpoint-up(md) {
    background-color: $body-background-md-color;
  }

  #root {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - #{$total-header-height-xs});
  }

  @include media-breakpoint-up(md) {
    background-color: $body-background-md-color;
    padding-top: $total-header-height;
    #root {
      height: calc(100vh - #{$total-header-height});
    }
  }
  .number-font {
    font-family: Roboto;
  }
  @include media-breakpoint-up(md) {
    padding-top: $total-header-height;
  }
}

.content-wrapper {
  min-height: 390px
}

.footer-container {
  //width: 1425px;
  //background: $footer-background-color;
}

.container-fixed-width {
  max-width: 1440px;
}

.col-xxl-10 {
  @media (min-width: 1440px) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}

.offset-xxl-1 {
  @media (min-width: 1440px) {
    margin-left: 8.33333%;
  }
}
