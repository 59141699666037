@import '../styles/variables';

.pagination {

  :global {
    .page-item {

      &.active {
        .page-link {
          background-color: $naranja;
          color: white;
        }
      }

      &.disabled {
        .page-link {
          color: gray;
        }
      }

      .page-link {
        border-width: 0px !important;
        font-family: Roboto;
        font-size: 14.4px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 1.2px;
        text-align: center;
        color: #999999;
      }
    }
  }
}
