@import '../styles/variables';
@import '../styles/mixins';

.navbar {
  .dropdown {
    position: static;

  }

  .dropdown-menu {
    top: 40px !important;
    transform: initial !important;
    border-width: 0px;
    border-radius: 0;
    padding: 0px;
  }
  .dropdown-item {
    width: 12rem;
    color: #7c7e95;
    font-size: 14px;
    color: #7c7e95;
    text-decoration: none;
  }
  .dropdown-item:hover {
    background-color: $category-dropdown-background-color-selected;
    //border-left: solid 4px #ff5733;
  }

  .dropdown-item:focus {
    outline: 5px auto $category-dropdown-background-color-selected;
  }
}
