@import '../styles/variables';
@import '../styles/mixins';

.featured_products {
  overflow-x: auto;
  @include media-breakpoint-up(md) {
    .title {
      font-size: 16px;
    }
  }

  .title {
    width: 114px;
    height: 19px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-color-primary;
  }

  .product_list {
    overflow-x: auto;
    justify-content: space-between;
  }

  .icon {
    width: 16px;
    height: 20px;
    color: $naranja;
    margin-right: 0.8rem;
  }

  .featured_product {
    margin-right: .5rem;
    width: 226px;
    min-width: 226px;
    height: 305px;

    @include media-breakpoint-up(md) {
      border: none;
      margin-right: .9rem;
      width: 214px;
      min-width: 214px;
      height: 290px;
    }

    &:last-child {
      border: none;
      margin-right: 0;
    }


  }

  .featured_product_extended {
    width: 100%;
    min-width: 100%;
    height: 107.1px;
    @include media-breakpoint-up(md) {
      width: 366px;
      min-width: 366px;
    }
  }

  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 2rem;
    }
  }
}

.pictureContainerClass {
  width: 100% !important;
  height: 195px !important;
  @include media-breakpoint-up(md) {
    height: 185px !important;
  }
}
