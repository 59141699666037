@import '../styles/variables';
@import '../styles/mixins';

.only_today_link {
  min-width: 20%;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.only_today_image{
  width: 100%;
  height: 100%;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.only_today_link_mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.only_today_image_mobile{
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.only_today{
  background-color: $body-background-md-color;

  @include media-breakpoint-up(md) {
    height: 326px;
    background-color: $body-background-color;
    margin-top: 2.1rem;
    border: solid 1px #e2e2e2;
  }



  .promotion {
    overflow-x: scroll;
    margin-top: 1.4rem;
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: normal;
      margin-top: 0rem;
      overflow-x: inherit;
    }
  }

  .promotionProductsContainer {
    @include media-breakpoint-up(md) {
      justify-content: space-around;
    }
  }
  .timer {
    height: 58px;
    @include media-breakpoint-up(md) {
      width: 12.45rem;
      min-width: 12.45rem;
      height: auto;
    }
    background-color:#1559bc;
    color: #ffffff;
    align-items: center;

    .icon {
      width: 28px;
      height: 28px;
      @include media-breakpoint-up(md) {
        margin-top: 2.8rem;
        margin-bottom: .6rem;
        width: 53px;
        height: 53px;
      }
    }

    .only, .today {
      text-transform: uppercase;
    }

    .only {
      //height: 19px;
      font-size: 0.9rem;
      @include media-breakpoint-up(md) {
        //height: 26px;
        font-size: 20.6px;
        font-weight: 500;
        font-size: 1rem;
      }
      font-family: Futura;
      color: #ffffff;
    }

    .today {
      //height: 40px;
      font-family: Futura;
      font-size: 0.9rem;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      @include media-breakpoint-up(md) {
        font-weight: bold;
        font-size: 30.6px;
      }
    }

    .daily_promotion {
      //@include media-breakpoint-up(md) {
      //  margin-bottom: 1rem;
      //}
      h1 {
        font-weight: 900;
        text-align: center;
        font-style: italic;
        font-size: 2.5em;
        text-transform: uppercase;
      }
      .count_down {
        color: #ffffff;
        display: flex;
        justify-content: flex-end;
        .hours_container, .minutes_container, .seconds_container {
          position: relative;
        }
        .hours, .minutes, .seconds {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 900;
          line-height: 1;
          text-align: center;
          justify-content: center;
          .value {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 17px;
            height: 28px;
            background-color: $naranja;
            margin-right: 3px;
            font-size: 17px;
          }
          @include media-breakpoint-up(md) {
            justify-content: center;
            .hours, .minutes, .seconds {
              .value {
                width: 22.1px;
                height: 36.5px;
                font-size: 22.1px;
              }
            }
          }
        }
        .separator {
          margin-right: 3px;
          font-weight: 600;
          font-size: 1.5em;
        }
        .label {
          font-size: 7.8px;
          margin-top: 0.15rem;
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
      .view_all {
        font-style: italic;
      }
      a {
        color:black;
        &:hover {
          color:black;
          text-decoration: none;
        }
      }
    }

  }
  .see_more {
    height: 20px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    a {
      color: $naranja;
      @include media-breakpoint-up(md) {
        color: #ffffff;
      }
      text-decoration: none;
    }
  }
}
