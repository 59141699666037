@import '../../styles/variables';
@import '../../styles/mixins';

.search {
  //background-color: #eee;
  flex-direction: row-reverse;
  @include media-breakpoint-up(md) {
    border: 1px solid $field-border-color;
    flex-direction: row;
  }
  display: flex;
  align-items: center;
  padding-left: 22px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 100%;
  input {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
    }
    background: transparent;
    border: none;
    outline: none;
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 13px;
    font-size: 14px;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $naranja;
    }
    &::ff5733 { /* Firefox 19+ */
      color: $naranja;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $naranja;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $naranja;
    }
  }

  &.show_input {
    border: 1px solid $field-border-color;
    @include media-breakpoint-down(md) {
      flex-grow: 1;
      margin-left: .5rem;
    }
    input {
      @include media-breakpoint-down(md) {
        display: flex !important;
        width: 100%;
      }
    }
  }
  button {
    width: 17px;
    color: white;
    border: none;
    // background: $field-border-color;
    font-size: 1.6em;
    border-radius: 0;
    .button_icon {
      width: 17px;
      height: 17.4px;
      color: #8094a1;
    }
    box-shadow: 0 0 0 0rem rgba(53, 53, 53, 0.25) !important;
  }
}
