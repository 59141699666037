@import '../styles/variables';

.payment_data {
  font-size: 0.8em;
  h3 {
    color: $main-color;
    font-size: 1.6em;
  }

  .button_text {
    // letter-spacing: 3px;
    text-transform: uppercase;
    width: 12.69rem;
    height: 3.06rem;
    background-color: #ff5733;
    font-size: .84rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    span {
      height: 18px;
    }
  }
}

.field {
  height: 40px;
  border: 1px solid $field-border-color;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  box-shadow: 0 0 0 0;
  &:focus {
    box-shadow: 0 0 0 0;
    border: solid 2px $field-border-color-focus;
  }
  .is-invalid {
    &:focus {
      box-shadow: 0 0 0 0;
    }
  }
}

.container{
  .container_summary {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 2rem;

    .summary {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 3px 3px 5px 5px #888888;
      padding: 2rem;
      width: 40rem;
    }
  }
}
