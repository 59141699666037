@import '../styles/variables';
@import '../styles/mixins';

.header {
  background-color: $naranja;
  color: white;
  button {
    span {
      color: white;
    }
  }
}

.button {
  color: white;
  background-color: $naranja;
  border-color: $naranja;
}
