@import '../styles/variables';
@import '../styles/mixins';

$font-color: #525252;

.checkout_workflow {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  .line_step {
    //width: 89%;
    border: solid 1px #aaa8ac;
    position: absolute;
    left: 1rem;
    right: 1rem;
    margin-top: .4rem;
    //margin-left: 1.5rem;
    z-index: -1;
  }
  .step {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: .88rem;
    .circle {
      width: 15px;
      height: 15px;
      border: solid 1px #aaa8ac;
      background-color: #f6f6f6;
      border-radius: 50%;
    }
    .title {
      height: 1.01rem;
      font-family: Roboto;
      color: #808298;
      margin-top: .44rem;
      text-align: center;
    }
    .active {
      background-color: $naranja !important;
      border-color: $naranja !important;
    }
    .complete {
      background-color: $naranja !important;
      border-color: $naranja !important;
    }
    .active_title {
      height: 19px;
      color: $naranja;
    }
  }
}
