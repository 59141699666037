@import '../styles/variables';
@import '../styles/mixins';

.product_detail {
  //margin-left: 10.6rem;
  //margin-right: 6.8rem;
  color: $product-card-color;
  .frame {

  }
  .summary {
    //padding: 0px;
    background-color: #ffffff;
  }
  .gallery {
    min-width: 100%;
    width: 100%;
    border-bottom: solid 2px #f6f6f6;
    background-color: #ffffff;
  }

  .title {
    display: flex;
    align-items: center;
    height: 40px;

    &.selected {
      span {
        background-color: #ffffff;
      }
    }
    span {
      background-color: #ececec;
      height: 100%;
      font-size: .88rem;
      color: #ff5733;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 1rem;
    }
  }
  .content {
    background-color: #ffffff;
    padding: 0.5rem 1rem 1.5rem 1rem;
    font-size: 12px
  }
  @include media-breakpoint-up(md) {
    .gallery {
      min-width: 60%;
      width: 60%;
      border-right: solid 2px #f6f6f6;
      border-bottom: none;
    }
    .content {
      min-height: 25rem;
    }
    .title {
      height: 61px;
      &.selected {
        span {
          border-top: solid 4px #ff5733;
        }
      }
      span {
        padding-left: 2rem;
        font-weight: bold;
      }
    }
    .content {
      padding: 1.5rem 1rem 1.5rem 1rem;
      font-size: 13px
    }
  }
}

.main_carousel_row {
  border-bottom: 40px solid transparent;
  text-align: -webkit-center;
}
