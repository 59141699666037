@import '../../styles/variables';

.title {
  font-size: 0.88rem;
  height: 16px;
  font-family: Roboto;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $text-color-primary;
  .count {
    font-size: 0.8rem;
    height: 15px;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ff5733;
    margin-left: 4px;
  }
}
