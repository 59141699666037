@import '../styles/variables';
@import '../styles/mixins';

.product {
  color: $product-card-color;
  overflow: hidden;

  border-radius: 2px;
  box-shadow: 0 2px 4px 0 #c8c8c9;
  background-color: #ffffff;

  a, a:hover, a:visited, a:focus {
    text-decoration: none;
    color: $product-card-color;
  }

  .product_data {
    display: flex;
    justify-content: center;
    .info {
      position: relative;
      width: 13.9rem;
      padding: 1.75rem 1rem 0.25rem 1rem;
    }
  }
}

.picture {
  height: $product-card-picture-height-extended;
  width: $product-card-picture-width-extended - 1.5rem;
  min-width: $product-card-picture-width-extended - 1.5rem;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    object-fit: scale-down;
  }
}

.separator {
  width: 2.1px;
  height: 108px;
  border: solid 0.7px #c8c8c9;
}
.price, .price_and_point, .name, .description {
  //margin-left: 1.1rem;
}

.name {
  //margin-top: 1.8rem;
  text-overflow: ellipsis;
  font-size: 0.75em;
  line-height: 1.25;
  color: $text-color-primary;
  overflow: hidden;
  white-space: nowrap;
  //
  // @include media-breakpoint-up(md) {
  //   color: $product-card-color;
  //   font-size: 0.8em;
  // }
  //
  // @include media-breakpoint-up(lg) {
  //   line-height: 1.1em;
  // }

}

.description {
  font-size: 0.8em;
  line-height: 1.15;
  color: #aaa8ac;
  overflow: hidden;
  width: 12.4rem;
  height: 1.8rem;
}

.price {
  margin-top: 0.2rem;
  height: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $text-color-primary;
  font-size: 15px;

  // height: $product-card-block-height;
  // font-size: 0.9em;
  // @include media-breakpoint-up(lg) {
  //   font-size: 1em;
  // }
}

.price_and_point {
  height: $product-card-block-height * 2;
}

.add_to_cart {
  height: $product-card-block-height * 1.2;
  font-size: 0.9em;

  @include media-breakpoint-up(lg) {
    font-size: 1em;
  }
}

@include media-breakpoint-up(md) {
  // border: 4px solid $product-card-border-color;

  .add_to_cart {
    border-top: 2px solid $product-card-border-color;
  }
  .picture {
    width: $product-card-picture-width-extended;
    min-width: $product-card-picture-width-extended;
  }
}

.installmentsBanner {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
