@import '../styles/variables';

.cart {
  //margin-left: 10.6rem;
  //margin-right: 7.5rem;
  font-size: 0.8em;
  h2 {
    height: 1.62rem;
    font-family: Roboto;
    font-size: 1.25rem;
    color: #414141;
  }
  .icon {
    width: 20px;
    height: 17px;
    color: #ff5733;
    margin-right: 10px;
  }

  .text_gray {
    font-size: 14px;
    color: #7c7e95;
  }

  .total_quantity {
    height: 19px;
  }
  .button_text {
    // letter-spacing: 3px;
    text-transform: uppercase;
  }

  .separator_vertical {
    height: auto;
    border-left: 2px solid $header-border-color;
  }
  .border_top_bottom {
    border-bottom: 2px solid $header-border-color;
    border-top: 2px solid $header-border-color;
  }
  .show {
    display: visible;
    opacity: 1;
    transition-delay: 0s;


  }
  .hide {
    display: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  }
}

.division {
  background-color: #ffffff;
  padding-left: 1.13rem;
  padding-top: 12px;
  padding-bottom: 1.38rem;
  margin-bottom: .63rem;

  &:last-child {
    margin-bottom: 0;
  }

  .division_name {
    height: 23px;
    font-size: 1.06rem;
    font-weight: bold;
    color: #414141;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .arrow {
      height: 21px;
      font-family: FontAwesome5Pro;
      font-size: 20px;
      font-weight: 300;
      color: #65697c;
    }
  }
  .division_body {
    margin-top: 1.06rem;
    margin-left: 1.6rem;
    .body_option {
      height: 32px;
      font-size: .88rem;
      font-weight: bold;
      color: #323c47;
    }
    .body_second_line {
      height: 32px;
      font-weight: normal;
      color: #8888a0;
    }
  }
  .margin_form {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .selected {
    color: #ff5733;
  }
}
