@import '../../styles/variables';
@import '../../styles/mixins';

.gallery {
  position: relative;
  //font-stretch: normal;
  //font-style: normal;
  //line-height: normal;
  //letter-spacing: normal;
  :global {
    .carousel {
      .slide {
        background: transparent;
        img {
          height: 14rem;
          max-width: 100%;
          width: auto;
          object-fit: scale-down;
        }
      }
      .control-arrow {
        font-family: FontAwesome5Pro;
        font-size: 30px;
        font-weight: 300;
        color: #65697c;
      }
      .thumbs {
        display: flex;
        justify-content: center;
        padding: 0;
        .thumb {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 64px;
          height: 64px;
          border: 1px solid #ddd;
          img {
            max-width: 100%
          }
          &.selected {
            border: 1px solid $main-color;
          }
        }
      }
    }
    @include media-breakpoint-up(md) {
      .carousel {
        .slide {
          img {
            height: 28.13rem;
          }
        }
      }
    }
  }
}
