@import '../styles/variables';
@import '../styles/mixins';

.home {
  //@include media-breakpoint-up(md) {
  //  width: 1425px;
  //}
  //.body_container {
  //  @include media-breakpoint-up(md) {
  //    margin-left: 10.6rem;
  //    margin-right: 7.5rem;
  //  }
  //  .margin_md_4_2_rem {
  //    @include media-breakpoint-up(md) {
  //      margin-right: 4.2rem;
  //    }
  //  }
  //}
  .home_banner {
    //@include media-breakpoint-up(md) {
    //  width: 1440px;
    //  margin-left: -0.45rem;
    //}
  }

  .inner_links {
    //width: 100%;
    //@include media-breakpoint-up(md) {
    //  width: 1440px;
    //  margin-left: 0.5rem;
    //}
    //display: flex;
    height: 81px;
    box-shadow: 0 3px 5px 0 $header-shadow-color;
    background-color: #ffffff;
    align-items: center;
    justify-content: space-evenly;
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
    .box_link {
      &:hover {
        cursor: pointer;
      }
      align-items: center;
      @include media-breakpoint-up(md) {
        margin-left: 3rem;
        margin-right: 3rem;
        align-items: normal;
      }
      .title {
        height: 11px;
        font-family: Roboto;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #676887;
        @include media-breakpoint-up(md) {
          height: 16px;
          font-size: 14px;
          .title_remark {
            color: $naranja;
          }
        }
      }
      .label {
        height: 11px;
        font-family: Roboto;
        font-size: 10px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $naranja;
        @include media-breakpoint-up(md) {
          font-size: 13px;
          line-height: 1.15;
        }
      }
    }
    .link_separator {
      width: 1px;
      height: 50px;
      border: solid 0.7px #c8c8c9;
    }

    .icon {
      width: 32px;
      height: 29px;
      font-size: 29px;
      font-weight: 300;
      color: $naranja;
    }
  }

  @include media-breakpoint-up(lg) {
    .inner_links {
      flex-wrap: nowrap;
      .link {
        border-bottom: 2px solid $header-border-color;
        border-right: 2px solid $header-border-color;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
