@import '../styles/variables';

.product_installments_banner {
  height: 23.7px;
  width: 9rem;
  background-color: $naranja;
  justify-content: center;
  align-items: center;
  display: flex;
  .font {
    font-family: Roboto;
    font-size: 9.5px;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.52px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
  }
}
