.price_with_discount_container {
  .price_with_discount {
    text-decoration: line-through;
    
    font-weight: bold;
    font-size: 14.2px;
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aaa8ac;
  }
  .price {
    line-height: initial;
  }
}
.max_installments {
  width: 70%;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2bb020;
  margin-top: .2rem;
}
.discount {
  width: auto;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1559bc;
  text-transform: uppercase;
  margin-top: .2rem;
}
.discount_same_line {
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1559bc;
  padding-bottom: 0.1rem;
  text-transform: uppercase;
}
