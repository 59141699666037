@import '../../styles/variables';
@import '../../styles/mixins';

.featured_products {
  .title {
    font-size: 2em;
    text-transform: uppercase;
    color: $main-color;
    font-weight: 600;
  }
}

.extendedViewProductsContainerClassName {
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
