@import '../../styles/variables';
@import '../../styles/mixins';

.cart_action {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-weight: 600;
  font-size: 1.2em;

  .total {
    background-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    color: #545454;
  }
  .total_color {
    color: $naranja;
  }
  .buy {
    text-transform: uppercase;

    a {
      height: 49px;
      line-height: 49px;
      width: 100%;
      background-color: #ff5733;
      text-transform: uppercase;
      text-align: center;
      vertical-align: center;
      font-size: .81rem;
      font-weight: bold;
      margin-top: 1.05rem;
      text-decoration: none;
      cursor: pointer;
      color: #ffffff;
    }

    a, a:hover, a:visited, a:focus {
      text-decoration: none;
      color: #ffffff;
    }

    a:hover {
      text-decoration: underline;
    }
  }
  .continue {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #ffffff;
    border: 2px solid $naranja;
    a, a:hover, a:visited, a:focus {
      text-decoration: none;
      color: $naranja;
    }
  }

}
