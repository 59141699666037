@import '../../../styles/variables';
@import '../../../styles/mixins';

.products-order {
  margin-top: 4px;

  .dropdown {
    a {
      padding-top: 4px;
      padding-bottom: 3px;
    }
    .dropdown-item {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 15px;
    }
  }
}
