@import '../../styles/variables';

.catalog_filter_item {
  font-size: 0.7rem;
  font-weight: 500;

  a, a:hover, a:focus, a:visited {
    color: #353535;
    text-decoration: none;
  }

  .label {
    height: 15px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff5733;
  }
  .icon {
    font-size: 0.9em;
    margin-right: 0.75rem;
    color: black;
  }

  .total {
    height: 15px;
    color: #999999;
    font-size: 0.84rem;
  }
  .selected {
    font-style: italic;
    &.category_color_1 {
      color: $brand-color-1;
    }
    &.category_color_2 {
      color: $brand-color-2;
    }
    &.category_color_3 {
      color: $brand-color-3;
    }
    &.category_color_4 {
      color: $brand-color-4;
    }
    &.category_color_5 {
      color: $brand-color-5;
    }
    &.category_color_6 {
      color: $brand-color-6;
    }
    &.category_color_7 {
      color: $brand-color-7;
    }
    &.category_color_8 {
      color: $brand-color-8;
    }
  }

  :global {
    .custom-control-label, .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border-color: $field-border-color;
        background-color: transparent;
        color: transparent;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='rgb(#{red($secondary), green($secondary), blue($secondary)})' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
    }
  }
}
