@import '../../../styles/variables';
@import '../../../styles/mixins';

.products-order :global {
  display: flex;
  align-items: center;

  .title {
    text-transform: uppercase;
    font-size: 0.7em;
    white-space: nowrap;
    @include media-breakpoint-up(md) {
      font-size: 0.8em;
    }

  }

  .btn-order {
    font-size: 0.75rem;
    text-decoration: none;
    color: $main-color;
    border-radius: 1em;
    padding: 0.20rem 1rem;
    border: 1px solid $main-color;

    &.active, &:hover {
      background-color: $main-color;
      color: white;
    }

    &.less_value {
      border-right: none;
      border-radius: 1em 0 0 1em;
      margin-left: 0.25rem;
    }

    &.more_value {
      border-radius: 0;
    }

    &.more_relevant {
      border-left: none;
      border-radius: 0 1em 1em 0;
      margin-right: 0.25rem;
    }

    @include media-breakpoint-up(md) {
      &.less_value {
        border: 1px solid $main-color;
        border-radius: 1em;
        margin-right: 0.25rem;
      }

      &.more_value {
        border-radius: 1em;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
      }

      &.more_relevant {
        border: 1px solid $main-color;
        border-radius: 1em;
        margin-left: 0.25rem;
      }
    }
  }
}

.icon {
  color: $naranja;
  margin-right: .6rem;
  width: 10px;
  height: 10px;
  margin-bottom: 1px;
}

.title {
  height: 20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  color: #ff5733;
  text-transform: uppercase;
}

.menu_items {
  font-style: italic;
  background-color: rgba(#ffffff, 0.8);
  transform: translate3d(-130px, 31px, 0px) !important;
  margin-left: 0rem !important;
  padding-right: 0.6rem;

  @include media-breakpoint-up(md) {
    padding-right: 0.5rem;
    transform: translate3d(-1px, 31px, 0px) !important;
  }

  a {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(#ffffff, 0);
    &:hover, &:focus {
      background-color: inherit;
      border-bottom: 1px solid $main-color;
      outline-color: rgba(#ffffff, 0.9);
    }
    &:active {
      color: #d6d6d6;
      background-color: rgba(#ffffff, 0.9);
    }
    .drop_item_text {
      color: rgba($main-color, .7) !important;
    }
  }
}
