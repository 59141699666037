@import '../../styles/variables';

.delivery_summary {
  font-size: 1.1em;
  h2 {
    font-size: 1.6em;
    color: $main-color;
  }
}
.separator {
  border-bottom: 1px solid $header-border-color;
}
