@import '../styles/variables';

.overlay {
  position: fixed;
  background: $overlay-color;
  z-index: 1050;
  &.visible {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
