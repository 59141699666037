@import '../styles/variables';
@import '../styles/mixins';

.check_out_summary {
  font-size: 0.8em;
  h2 {
    font-size: 1.6em;
    color: $main-color;
  }

  .button_text {
    // letter-spacing: 3px;
    text-transform: uppercase;
  }

  .checkout_data {
    .row {
      div {
        padding: 0 !important;
      }
    }
    .label {
      font-weight: 600;
      color: $main-color;
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }
  .separator_vertical {
    height: auto;
    border-right: 2px solid $header-border-color;
  }
}
