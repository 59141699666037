@import '../styles/variables';
@import '../styles/mixins';

.cart_summary {
  color: $header-color;
  a, a:hover, a:visited, a:focus {
    color: $header-color;
    text-decoration: none;
  }

  img {
    width: 20px;
  }
  .label {
    line-height: 1.2;
    .totalContainer {
      font-weight: 450;
    }
    .quantity {
      font-family: UniNeue;
    }
    .total {
      font-family: UniNeue;
    }
    .totalPrefix {


    }
  }

  @include media-breakpoint-up(lg) {
    img {
       width: 40px;
    }

    .label {
      line-height: 1;
      .totalContainer {
        font-size: 2.2em;
      }
    }
  }

  .summary_container {
    background: red;
  }
}
