@import '../styles/mixins';
@import '../styles/variables';

.catalog {
  //margin-left: 9rem;
  //margin-right: 7.5rem;
  .current_category_name {
    height: 21px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-color-primary;
    .current_category_product_count {
      height: 16px;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      color: #8888a0;
    }
  }
  .separator {
    width: 100%;
    border: solid 1px #e2e2e2;
  }
}

.catalog_header {
}

.catalog_filters {
  // border-right: 2px solid $product-card-border-color;
}
