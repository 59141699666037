@import '../styles/variables';
@import '../styles/mixins';

.product_free_delivery_banner {
  width: 92.1px;
  bottom: 5px;
  height: 19px;
  border-radius: 1.9px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 0.7px $naranja;
  background-color: #ffffff;
  position: absolute;

  .font {
    height: 11px;
    font-size: 9.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $naranja;
    text-transform: uppercase;
  }
  @include media-breakpoint-up(md) {
    bottom: 10px;
    left: 1rem;
  }
}
