@import '../../styles/variables';
@import '../../styles/mixins';

.cart_summary_details {
  font-size: 0.85rem;
  background-color: #ffffff;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  .icon {
    width: 30px;
    color: $main-color;
    padding-bottom: 10px;

    @include media-breakpoint-up(md) {
      width: 45px;
      padding-bottom: 0;
    }
  }

  .separator {
    margin-left: -1rem;
    border-bottom: solid 1px #f6f6f6;
  }
  .title_box {
    margin-top: .75rem;
    margin-bottom: .75rem;

    .title {
      font-size: 1.06em;
      height: 23px;
      color: #414141;
    }
  }
  .sub_total {
     margin-top: .85rem;
     align-items: center;

    .sub_total_label {
      height: 19px;
      font-size: 0.80rem;
      line-height: 0.7rem;
      color: #aaa8ac;
    }
    .sub_total_value {
       height: 21px;
       font-size: 1rem;
       font-weight: bold;
       text-align: right;
       color: #ff5733;
    }
    .delivery_total_value {
      height: 19px;
      font-size: 0.88rem;
      font-weight: bold;
      text-align: right;
      color: #414141;
    }
  }

  .sub_total_bottom {
    margin-bottom: 1rem;
  }

  .installments {
    font-size: 11px;
    line-height: 1.36;
    text-align: right;
    color: $naranja;
  }

  .total {
    height: 1.31rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ff5733;
  }
  .total_value {
    height: 24px;
    font-size: 1.13rem;
    font-weight: bold;
    text-align: right;
    color: #ff5733;
  }

  .finalizar_compra_button {

    a {
      height: 49px;
      line-height: 49px;
      width: 100%;
      background-color: #ff5733;
      text-transform: uppercase;
      text-align: center;
      vertical-align: center;
      font-size: .81rem;
      font-weight: bold;
      margin-top: 1.05rem;
      text-decoration: none;
      cursor: pointer;
      color: #ffffff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .seguir_comprando_link {
    height: 1.13rem;
    font-size: .81rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0.78rem;
    margin-bottom: .88rem;
    a {
      color: #ff5733;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .sub_total_partial {
    text-transform: uppercase;
    font-style: italic;
    font-size: 1.1em;
  }
  // .sub_total {
  //   text-transform: uppercase;
  //   font-style: italic;
  //   font-size: .7em;
  // }
  // .sub_total_value {
  //   font-size: 1.5em;
  //   font-weight: 600;
  // }
  .sub_total_value_partial {
    font-size: 3.5em;
    font-weight: 600;
  }

}
