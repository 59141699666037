@import '../styles/variables';

.cart {
  //margin-left: 10.6rem;
  //margin-right: 7.5rem;
  font-size: 0.8em;
  h2 {
    height: 1.62rem;
    font-family: Roboto;
    font-size: 1.25rem;
    color: #414141;
  }
  .icon {
    width: 20px;
    height: 17px;
    color: #ff5733;
    margin-right: 10px;
  }

  .text_gray {
    font-size: 14px;
    color: #7c7e95;
  }

  .total_quantity {
    height: 19px;
    margin-left: 1.9rem;
  }
  .button_text {
    // letter-spacing: 3px;
    text-transform: uppercase;
  }

  .separator_vertical {
    height: auto;
    border-left: 2px solid $header-border-color;
  }
  .border_top_bottom {
    border-bottom: 2px solid $header-border-color;
    border-top: 2px solid $header-border-color;
  }
}
