@import '../styles/mixins';

.add_to_cart_button {
  height: 100%;

  img {
    width: 25px;
  }

  .add_to_cart_button_icon, .add_to_cart_button_icon_white {
    margin-right: 0.25rem;
  }
  .add_to_cart_button_icon_white {
    display: none;
  }

  &.dark {
    .add_to_cart_button_icon {
      display: none;
    }
    .add_to_cart_button_icon_white {
      display: inline-block;
    }
  }

  &.small {
    height: 50px;
    width: 50px;
    padding: 0.75rem;

    &:hover:not(:disabled) {
      background-color: $main-color;
    }

    img {
      width: 100%;
    }

    &.border {
      border: 1px solid $product-card-border-color;
      border-color: $product-card-border-color;
      border-radius: 4px;
    }
  }

  &.big {
    height: 90px;
    width: 90px;

    &:hover:not(:disabled) {
      background-color: $main-color;
    }

    img {
      width: 100%;
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: none;
  }
}

.add_to_cart_button_text {
  font-size: 0.8em;
  text-transform: uppercase;
}
