@import '../styles/variables';
@import '../styles/mixins';

.product {
  color: $product-card-color;
  position: relative;

  box-shadow: 0 2px 4px 0 #c8c8c9;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  height: 100%;

  @include media-breakpoint-up(md) {
    .price_and_point {
      height: $product-card-block-height;
    }
  }

  a, a:hover, a:visited, a:focus {
    text-decoration: none;
    color: $product-card-color;
  }
}

.picture {
  width: 135px;
  min-width: 135px;
//  height: 185px;
//  border-radius: 1.9px;
// }
//height: 140px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    object-fit: scale-down;
  }
  @include media-breakpoint-up(md) {
    //height: $product-card-picture-height;
    height: 155px;
    width: auto;
    border-bottom: 1px solid $product-card-border-color;
  }
}

.product_info {
  position: relative;
  overflow: hidden;
}

.price, .price_and_point, .name, .description {
  //margin-left: 1.1rem;
}

.name {
  min-height: 1em;
  max-width: 100%;
  //margin-top: 0.7rem;
  margin-bottom: .1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8em;
  line-height: 1.15;
  color: $text-color-primary;
  //
  // @include media-breakpoint-up(md) {
  //   color: $product-card-color;
  //   font-size: 0.8em;
  // }
  //
  // @include media-breakpoint-up(lg) {
  //   line-height: 1.1em;
  // }

}

.description {
  font-size: 0.8em;
  line-height: 1.15;
  color: #aaa8ac;
  overflow: hidden;
  width: 11.2rem;
  height: 1.8rem;
}

.price {
  margin-top: 0.2rem;
  width: 69px;
  height: 16px;
  font-weight: bold;
  color: $text-color-primary;
  font-size: 14.2px;
  // height: $product-card-block-height * 1.2;
  // @include media-breakpoint-up(lg) {
  //   font-size: 1.6em;
  // }
}

.add_to_cart {
  height: $product-card-block-height * 1.2;
  font-size: 0.9em;

  @include media-breakpoint-up(lg) {
    font-size: 1em;
  }
}

.installmentsBanner {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
