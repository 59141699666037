@import '../../styles/variables';

.order_return_modal {

  .button_text {
    text-transform: uppercase;
    width: 12.69rem;
    height: 3.06rem;
    background-color: $primary;
    font-size: .84rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;

    span {
      height: 18px;
    }
  }

  .submit_button {
    background-color: $primary;
  }
}
