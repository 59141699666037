@import '../styles/variables';
@import '../styles/mixins';

.navs_container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  //width: 100%;
  //@include media-breakpoint-up(md) {
  //  max-width: 1440px;
  //}
  background: $header-background-color;
}

.header_subheader_separator {
  border: solid 1px $naranja;
  position: absolute;
  bottom: 0;
}

.header {

  color: $header-color;
  height: $header-height-xs;
  //box-shadow: 0 2px 4px 0 $header-shadow-color;
  background-color: #ffffff;
  padding: 0px;

  @include media-breakpoint-up(md) {
    height: $header-height;
  }

  :global {
    .advance_search {
      cursor: pointer;
      color: $header-color;
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: .06rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .icon {
        margin-left: 0.5rem;
        font-size: 1rem;
        color: $main-color;
      }
    }

    .height{
      height: 70px !important;
    }
  }

  .logos {
    margin-left: 1rem;
    //margin-top: .93rem;
    //margin-bottom: 1.28rem;
    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
    display: flex;
    align-items: center;
    .brand_logo {
      .logo {
        width: 5.65rem;
        @include media-breakpoint-up(md) {
          width: 7.5rem;
          height: 2.2rem;
        }
      }
    }
  }

  .menu_items_container {
    //height: $total-header-height-xs;
    .line_divisor {
      width: 1px;
      height: 28px;
      border: solid 1px $header-shadow-color;
    }

    align-items: center;

    @include media-breakpoint-up(md) {
      //margin-top: .88rem;
      //margin-left: 2.83rem;
      margin-left: 1rem;
    }
    color: #7c7e95;
    font-size: 13px;

    .search_container {
      height: 2.69rem;
      width: 592px;
    }

    a, a:hover, a:visited, a:focus {
      text-decoration: none;
      color: #7c7e95;
      font-size: 13px;
    }

    .box_with_icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        width: 1em;
        height: 1em;
        font-size: 19px;
        font-weight: 300;
        color: $naranja;
        margin-bottom: 4px;
      }
    }
    .login_button {
      width: 126px;
      display: flex;
      flex-direction: row;
      .arrow {
        width: 6.9px;
        color: $naranja;
        margin-left: 4px;
        margin-bottom: 2px;
        align-self: flex-end;
      }
    }
    .cart_button {
      //width: 108px;
      text-align: center;
    }
    .help_center {
      margin-left: 19px;
    }
  }

  .sessionmenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .buttonSearch {
    width: 5rem;
    border-radius: 0.5rem;
  }

  .toggle_button {
    font-size: 2em;
    color: $header-burger-menu;
  }


  @include media-breakpoint-up(lg) {

    .menu_items_container {
      flex-direction: row;
    }

  }
}
