@import '../../styles/mixins';

.view_mode_container :global {
  display: flex;
  padding-right: 0px;
  margin-top: 0rem;
  flex-wrap: wrap;
  @include media-breakpoint-up(xs) {
    justify-content: space-around;
  }
  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }
}

.filter_and_sorting {
  justify-content: flex-end;
}
