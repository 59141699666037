@import '../styles/variables';
@import '../styles/mixins';
.banner {
  width: 100%;
  .border {
    border: solid 1px #e2e2e2;
    background-color: $object-background-color;
  }

  :global {
    .control-dots .dot {
      background: $carousel-indicator-color;
      box-shadow: none;
      opacity: 1;
      height: 10px;
      width: 10px;
      &.selected {
        background: $carousel-indicator-color-selected;
      }
    }
  }

  .normal {
    //width: 165px;
    height: 245px;
    box-shadow: 0 2px 4px 0 #c8c8c9;
    @include media-breakpoint-up(md) {
      //width: 259px;
      height: 290px;
      box-shadow: 0 0 0 0 #ffffff;
    }
    text-align: center;
    .picture {
      width: auto;
      max-width: 115px;
      max-height: 100px;
      margin-top: .95rem;
      margin-bottom: .95rem;
      @include media-breakpoint-up(md) {
        max-height: 118.9px;
        //max-width: 180px;
        margin-top: 1.05rem;
        margin-bottom: 0.82rem;
      }
    }
    .name {
      height: 0.88rem;
      font-family: Roboto;
      font-size: .75rem;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #1559bc;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        height: 0.8rem;
        font-size: 14.4px;
        letter-spacing: 0.6px;
      }
    }
    .offer_title {
      height: 11px;
      font-family: Roboto;
      font-size: 10px;
      text-align: center;
      margin-top: .7rem;
      color: $text-color-primary;
      @include media-breakpoint-up(md) {
        height: 16px;
        font-family: Roboto;
        font-size: 14px;
      }
    }
    .offer_target {
      height: 19px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 900;
      text-align: center;
      color: #414141;
      color: $text-color-primary;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        height: 23px;
        font-size: 19.2px;
      }
    }
    .see_more {
      margin-top: 1.3rem;
      height: 24px;
      font-size: 0.80rem;
      line-height: 1.85;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 1.08px;
      color: $naranja;
      text-transform: uppercase;
    }
    .separator {
      width: 165px;
      border: solid 0.6px #f4f3f3;
      @include media-breakpoint-up(md) {
        width: 258px;
        margin-top: 0.3rem;
        border-width: 1px;
      }
      margin-bottom: 0.2rem;
    }
  }
  .wide {
    height: 10.44rem;
    box-shadow: 0 2px 4px 0 #c8c8c9;

    @include media-breakpoint-up(md) {
      //width: 580px;
      height: 290px;
      box-shadow: 0 0 0 0 #ffffff;
    }

    .picture {
      margin-right: .5rem;
      max-width: 171px;
      height: 160px;

      @include media-breakpoint-up(md) {
        width: auto;
        max-height: 266px;
        max-width: 317px;
        margin-top: .75rem;
        margin-bottom: 0.82rem;
        margin-right: 2rem;
        height: 290px;
      }
    }

    .name, .offer_target, .see_more, .separator {
      text-transform: uppercase;
    }

    .name, .offer_title, .offer_target, .see_more, .separator {
      margin-left: 1.7rem;
      @include media-breakpoint-up(md) {
        margin-left: 2.7rem;
        text-transform: uppercase;
      }
    }

    .name {
      margin-top: 1.5rem;
      height: 19px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0.58px;
      color: #1559bc;

      @include media-breakpoint-up(md) {
        margin-top: 3.6rem;
        height: 24px;
        font-family: Roboto;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.75px;
      }
    }
    .separator {
      width: 16px;
      height: 1px;
      border: solid 2px $naranja;
      margin-top: 0.9rem;
    }
    .offer_title {
      width: 69px;
      height: 16px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #414141;

      @include media-breakpoint-up(md) {
        height: 26px;
        width: auto;
        // font-family: Futura;
        font-size: 20px;
        font-weight: 500;
        color: $text-color-primary;
        margin-top: 1.5rem;
      }
    }
    .offer_target {
      height: 56px;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #414141;
      @include media-breakpoint-up(md) {
        height: 32px;
        // font-family: Futura;
        font-size: 25px;
        color: $naranja;
      }
    }
    .see_more {
      width: 85px;
      height: 20px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.67;
      letter-spacing: 1px;
      color: #ff5733;
      @include media-breakpoint-up(md) {
        margin-top: 4rem;
        height: 30px;
        font-family: Roboto;
        font-size: 13px;
        font-weight: bold;
        line-height: 2.31;
        letter-spacing: 1.08px;
        color: $naranja;
      }
    }
  }
  .see_more {
    a, a:hover, a:visited, a:focus {
      text-decoration: none;
      color: $naranja;
    }
  }
  .special_day {
    min-height: 200px;
    min-width: 375px;
    width: 100%;

    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      //width: 580px;
      height: 359px;
    }
    .box {
      width: 165px;
      height: 156px;
      @include media-breakpoint-up(md) {
        width: 15.9rem;
        height: 17.5rem;
      }
      border-radius: 2px;
      background-color: #ffffff;
      margin-right: 1.4rem;
      margin-top: .2rem;
      text-align: center;
    }

    .name, .offer_target, .see_more, .separator {
      text-transform: uppercase;
    }

    .name {
      margin-top: 2.25rem;
      height: 14px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #1559bc;
      @include media-breakpoint-up(md) {
        margin-top: 3.6rem;
        height: 24px;
        font-size: 21px;
        letter-spacing: 0.87px;
      }
    }
    .picture {
      width: 100%;
      min-width: 100%;
      object-fit: cover;
      @include media-breakpoint-up(md) {
        height: 359px;
      }
    }
    .separator {
      width: 16px;
      height: 1px;
      border: solid 2px $naranja;
      margin-top: 0.9rem;
    }
    .offer_title {
      margin-top: .69rem;
      height: 14px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $text-color-primary;
      @include media-breakpoint-up(md) {
        height: 26px;
        // font-family: Futura;
        font-size: 20px;
        font-weight: 500;
        margin-top: 1.15rem;
      }
    }
    .offer_target {
      height: 21px;
      font-family: Roboto;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #414141;
      @include media-breakpoint-up(md) {
        height: 32px;
        // font-family: Futura;
        font-size: 27px;
        color: $naranja;
        margin-left: 9px;
        width: 237px;
      }
    }
    .see_more {
      margin-top: 1.81rem;
      height: 20px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 1px;
      text-align: center;
      color: $naranja;
      @include media-breakpoint-up(md) {
        margin-top: 4rem;
        height: 30px;
      }
      a, a:hover, a:visited, a:focus {
        color: $naranja !important;
      }
    }
  }
  .offer_bomb {

    border: solid 1px #e2e2e2;
    background-color: $body-background-md-color;
    @include media-breakpoint-up(md) {
      background-color: #ffffff;
      //width: 33.45rem;
      height: 22.44rem;
    }
    .box {
      color: #ffffff;
      width: 181px;
      height: 339px;
      border-radius: 2px;
      margin-right: 1.4rem;
      text-align: center;
      background-color: #1559bc;

      .icon {
        width: 4.7rem;
        height: 5rem;
        margin-top: 3.68rem;
      }

      .name, .offer_target, .offer_title, .see_more, .separator {
        text-transform: uppercase;
      }

      .name {
        height: 14px;
        font-family: Roboto;
        font-size: .75rem;
        font-weight: 900;
        letter-spacing: 0.5px;
        color: #ffffff;

        @include media-breakpoint-up(md) {
          margin-top: .9rem;
          height: 20px;
          font-family: Roboto;
          font-size: 1.06rem;
          letter-spacing: 0.71px;
        }
      }
      .offer_title {
        height: 20px;
        font-family: Roboto;
        font-size: 1.06rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        width: 213px;
        @include media-breakpoint-up(md) {
          font-family: Futura;
          font-size: 1.25rem;
          text-align: center;
        }
      }
      .offer_target {
        width: 106px;
        height: 20px;
        font-family: Roboto;
        font-size: 17px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        @include media-breakpoint-up(md) {
          font-size: 23px;
          font-weight: bold;
        }
      }
      .see_more {


        @include media-breakpoint-up(md) {
          margin-top: 1.7rem;
          height: 17px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: 1px;
          text-align: center;
        }
      }
    }

    .box_product {
      background-color: #ffffff;
      .name {
        height: 35px;
        font-family: Roboto;
        font-size: 12px;
        line-height: 1.25;
        text-align: center;
        color: #414141;
      }
    }
    .box_mobile {
      height: 73px;
      color: #ffffff;
      text-align: left;
      background-color: #1559bc;
      .icon {
        width: 2.19rem;
        height: 2.5rem;
        margin-left: 1rem;
        margin-right: 0.7rem;
      }
      .name {
        font-family: Roboto;
        font-size: 0.75rem;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #ffffff;
        margin-top: 0.5rem;
      }
      .offer_title {
       font-family: Roboto;
       font-size: 1.06rem;
       font-weight: normal;
       font-stretch: normal;
       font-style: normal;
       line-height: normal;
       letter-spacing: normal;
       color: #ffffff;
       text-transform: uppercase;
      }
      .offer_target {
        font-family: Roboto;
        font-size: 1.06rem;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 0.5rem;
      }
    }
    .picture {
      width: 100%;
      min-width: 100%;
      @include media-breakpoint-up(md) {
        height: 359px;
        min-height: 359px;
        object-fit: cover;
      }
    }
    .see_more {
      text-transform: uppercase;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.67;
      letter-spacing: 1px;
      text-align: center;
      color: #ff5733;
    }
  }
}
